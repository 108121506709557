import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ArrayHelpers, FieldArray, Form, Formik } from "formik";
import { ConfirmModal, InputField, MenuButton, Spin } from "components";
import * as Yup from "yup";
import {
  AssetIncomeSource,
  AssetIncomeSourceType,
  Client,
  ConfirmModalRef,
} from "../../types";
import clsx from "clsx";
import { TrashIcon } from "@heroicons/react/24/outline";
import { find, reject, filter } from "lodash";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useApi } from "api";

interface ClientFormProps {
  initialValues: Omit<Client, "id" | "fullName" | "fax">;
  mutateFunction: (values: Omit<Client, "id" | "fullName" | "fax">) => void;
  goBack: () => void;
}

export const ClientForm: React.FC<ClientFormProps> = (props) => {
  const { initialValues, mutateFunction, goBack } = props;
  const params = useParams<{
    agencyId: string;
    advisorId: string;
    clientId: string;
  }>();
  const api = useApi();
  const queryClient = useQueryClient();
  const [currentAge, setCurrentAge] = useState<number>(
    initialValues.currentAge ?? 0,
  );
  const [spouseAge, setSpouseAge] = useState<number>(
    initialValues.spouseAge ?? 0,
  );
  const confirmModalRef = useRef<ConfirmModalRef>(null);

  const states = useQuery({
    queryKey: ["states"],
    queryFn: async () => {
      const response =
        await api.get<{ id: string; name: string }[]>("/api/v1/states/");
      return response.data;
    },
  });

  const assetIncomeSourceTypes = useQuery<AssetIncomeSourceType[]>({
    queryKey: ["assetIncomeSourceTypes"],
    queryFn: async () => {
      const response = await api.get<AssetIncomeSourceType[]>(
        "/api/v1/asset-income-sources/",
      );
      return response.data;
    },
  });

  const deleteSource = useMutation({
    mutationFn: async (sourceId: number) => {
      await api._delete(
        `/api/v1/clients/${params.clientId}/asset-income-sources/${sourceId}/`,
      );
    },
    onSuccess: () => {
      toast.success("Asset/Income Source deleted successfully");
      queryClient.invalidateQueries({
        queryKey: [
          "agency",
          params.agencyId,
          "advisor",
          params.advisorId,
          "client",
          params.clientId,
        ],
      });
    },
    onError: () => {
      toast.error("Error deleting source");
    },
  });

  if (
    !assetIncomeSourceTypes ||
    !assetIncomeSourceTypes.data ||
    assetIncomeSourceTypes.isLoading
  ) {
    return <Spin size="4xl" />;
  }

  const nullablePercent = () =>
    Yup.number()
      .typeError("Amount must be a number")
      .min(0, "Must be 0 or greater")
      .max(100, "Must less than or equal to 100")
      .nullable();

  const clientValidationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .min(2, "Must be at least 2 characters long.")
        .required("This field is required"),
      lastName: Yup.string()
        .min(2, "Must be at least 2 characters long.")
        .required("This field is required"),
      address: Yup.string()
        .min(2, "Must be at least 2 characters long.")
        .nullable(),
      state: Yup.string().required("This field is required"),
      postal: Yup.string()
        .matches(/^\d{5}$/, "Postal code must only contain digits.")
        .min(5, "Must be 5 characters long.")
        .max(5, "Must be 5 characters long.")
        .nullable(),
      gender: Yup.number().oneOf([1, 2]).required("This field is required"),
      filingStatus: Yup.number()
        .oneOf([1, 2, 3, 4])
        .required("This field is required"),
      currentAge: Yup.number()
        .typeError("Amount must be a number")
        .integer("Must be an round number")
        .min(18, "Must be 18 or older")
        .max(110, "Should be less than 110")
        .required("This field is required"),
      retirementAge: Yup.number()
        .typeError("Amount must be a number")
        .integer("Must be an round number")
        .max(110, "Should be less than 110")
        .required("This field is required")
        .test(
          "is-greater-than-current-age",
          "Must be greater than or equal to current age",
          (value: number) => value >= currentAge,
        ),
      targetRetirementIncome: Yup.number()
        .typeError("Amount must be a number")
        .min(0, "Must be 0 or greater")
        .required("This field is required"),
      phone: Yup.string()
        .min(10, "Phone number must have 10 digits.")
        .matches(/^\d{10}$/, "Phone number must only contain digits.")
        .nullable(),
      email: Yup.string().email("Must be a valid email address").nullable(),
      spouseFirstName: Yup.string().when(
        ["spouseLastName", "spouseAge", "spouseGender"],
        {
          is: (
            spouseLastName: string,
            spouseAge: number,
            spouseGender: number,
          ) => !!spouseLastName || !!spouseAge || !!spouseGender,
          then: (schema) =>
            schema
              .min(2, "Must be at least 2 characters long.")
              .required("This field is required"),
        },
      ),
      spouseLastName: Yup.string().when(
        ["spouseFirstName", "spouseAge", "spouseGender"],
        {
          is: (
            spouseFirstName: string,
            spouseAge: number,
            spouseGender: number,
          ) => !!spouseFirstName || !!spouseAge || !!spouseGender,
          then: (schema) =>
            schema
              .min(2, "Must be at least 2 characters long.")
              .required("This field is required"),
        },
      ),
      spouseGender: Yup.number().when(
        ["spouseFirstName", "spouseLastName", "spouseAge"],
        {
          is: (
            spouseFirstName: string,
            spouseLastName: string,
            spouseAge: number,
          ) => !!spouseFirstName || !!spouseLastName || !!spouseAge,
          then: (schema) =>
            schema.oneOf([1, 2]).required("This field is required"),
          otherwise: (schema) => schema.nullable(),
        },
      ),
      spouseAge: Yup.number().when(
        ["spouseFirstName", "spouseLastName", "spouseGender"],
        {
          is: (
            spouseLastName: string,
            spouseFirstName: string,
            spouseGender: number,
          ) => !!spouseLastName || !!spouseFirstName || !!spouseGender,
          then: (schema) =>
            schema
              .typeError("Amount must be a number")
              .integer("Must be an round number")
              .min(18, "Must be 18 or older")
              .max(110, "Should be less than 110")
              .required("This field is required"),
          otherwise: (schema) => schema.nullable(),
        },
      ),
      spouseEmail: Yup.string()
        .email("Must be a valid email address")
        .nullable(),
      assetIncomeSources: Yup.array().of(
        Yup.object().shape({
          monthlyIncome: Yup.number()
            .typeError("Amount must be a number")
            .min(0, "Must be 0 or greater")
            .required("This field is required"),
          value: Yup.number().when("hasValue", {
            is: true,
            then: (schema) =>
              schema
                .typeError("Amount must be a number")
                .min(0, "Must be 0 or greater")
                .required(),
          }),
          assetOwner: Yup.number().required("This field is required"),
          startAge: Yup.number().when(
            ["hasStartAge", "assetOwner"],
            ([hasStartAge, assetOwner], schema) => {
              if (hasStartAge) {
                const compareAge = assetOwner === 0 ? currentAge : spouseAge;
                return schema
                  .typeError("Amount must be a number")
                  .integer("Must be an round number")
                  .min(compareAge, `Must be ${compareAge} or older`)
                  .max(110, "Should be less than 110")
                  .required("This field is required");
              } else {
                return schema;
              }
            },
          ),
          annualGrowthRate: Yup.number().when("hasGrowthRate", {
            is: true,
            then: (schema) =>
              schema
                .typeError("Amount must be a number")
                .min(0, "Must be 0 or greater")
                .max(100, "Must less than or equal to 100")
                .required(),
          }),
          term: Yup.number().when("hasTerm", {
            is: true,
            then: (schema) =>
              schema
                .typeError("Amount must be a number")
                .integer("Must be an round number")
                .min(0, "Must be 0 or greater")
                .max(90, "Must less than or equal to 90")
                .required(),
          }),
          reinvest: Yup.number().when("hasReinvest", {
            is: true,
            then: (schema) =>
              schema
                .typeError("Amount must be a number")
                .min(0, "Must be 0 or greater")
                .required(),
          }),
        }),
      ),
      federalTaxRateCurrent: nullablePercent(),
      federalTaxRateRetirement: nullablePercent(),
      stateLocalTaxRateCurrent: nullablePercent(),
      stateLocalTaxRateRetirement: nullablePercent(),
      capitalGainsTaxRateCurrent: nullablePercent(),
      capitalGainsTaxRateRetirement: nullablePercent(),
      retirementGoals: Yup.array()
        .min(1, "Please select at least one option")
        .max(3, "Please select less than 3 options")
        .required("Please select at least one option"),
    },
    [
      ["spouseFirstName", "spouseLastName"],
      ["spouseFirstName", "spouseGender"],
      ["spouseFirstName", "spouseAge"],
      ["spouseLastName", "spouseAge"],
      ["spouseLastName", "spouseGender"],
      ["spouseAge", "spouseGender"],
    ],
  );

  return (
    <div>
      <ConfirmModal
        ref={confirmModalRef}
        titleText="delete asset/income source"
      />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          mutateFunction(values);
        }}
        validationSchema={clientValidationSchema}
      >
        {({ isSubmitting, errors, values, setFieldValue }) => {
          const canSelectAssetOwner =
            values.spouseFirstName &&
            values.spouseLastName &&
            values.spouseAge !== undefined &&
            values.spouseAge !== null &&
            values.currentAge !== undefined &&
            values.currentAge !== values.spouseAge;

          return (
            <Form>
              <div className="mt-8 py-2">
                <div className="text-base font-semibold text-gray-600">
                  Client Information
                </div>
              </div>
              <div className="mt-4 py-2">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <InputField
                    name="firstName"
                    label="First Name*"
                    type="text"
                  />
                  <InputField name="lastName" label="Last Name*" type="text" />
                  <InputField
                    name="gender"
                    label="Gender*"
                    type="radio"
                    selectValues={[
                      { id: 1, name: "Male" },
                      { id: 2, name: "Female" },
                    ]}
                  />
                  <InputField
                    name="currentAge"
                    label="Age*"
                    type="number"
                    onChange={(e) => {
                      setCurrentAge(parseInt(e.target.value));
                      setFieldValue("currentAge", parseInt(e.target.value));
                    }}
                  />
                  <InputField name="address" label="Address 1" type="text" />
                  <InputField name="address2" label="Address 2" type="text" />
                  <InputField
                    name="state"
                    label="State*"
                    type="select"
                    selectValues={states.data}
                  />
                  <InputField name="postal" label="Postal Code" type="text" />
                  <InputField name="email" label="Email" type="text" />
                  <InputField name="phone" label="Phone number" type="text" />
                  <InputField
                    name="filingStatus"
                    label="Filing Status*"
                    type="select"
                    selectValues={[
                      { id: 1, name: "Single" },
                      { id: 2, name: "Married Filing Jointly" },
                      { id: 3, name: "Married Filing Separately" },
                      { id: 4, name: "Head of Household" },
                    ]}
                  />
                  <InputField
                    name="retirementAge"
                    label="Retirement Age*"
                    type="text"
                  />
                  <InputField
                    name="targetRetirementIncome"
                    label="Target Retirement Income*"
                    type="text"
                  />
                </div>
                <div className="mt-4 py-2">
                  <span className="text-base text-gray-600">Spouse</span>
                </div>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <InputField
                    name="spouseFirstName"
                    label="First Name"
                    type="text"
                  />
                  <InputField
                    name="spouseLastName"
                    label="Last Name"
                    type="text"
                  />
                  <InputField
                    name="spouseGender"
                    label="Gender"
                    type="radio"
                    selectValues={[
                      { id: 1, name: "Male" },
                      { id: 2, name: "Female" },
                    ]}
                  />
                  <InputField
                    name="spouseAge"
                    label="Age"
                    type="text"
                    onChange={(e) => {
                      setSpouseAge(parseInt(e.target.value));
                      setFieldValue("spouseAge", parseInt(e.target.value));
                    }}
                  />
                  <InputField name="spouseEmail" label="Email" type="text" />
                </div>
                <div className="mt-4 py-2">
                  <div className="text-base font-semibold text-gray-600">
                    Retirement Goals*{" "}
                    <span
                      className={clsx(
                        "text-sm font-light",
                        errors.retirementGoals && "font-semibold text-red-600",
                      )}
                    >
                      (Select up to 3, min. 1)
                    </span>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
                  <InputField
                    name="retirementGoals"
                    label="Income Planning"
                    value="INCOME"
                    type="checkbox"
                  />
                  <InputField
                    name="retirementGoals"
                    label="Estate Planning"
                    value="ESTATE"
                    type="checkbox"
                  />
                  <InputField
                    name="retirementGoals"
                    label="Legacy for Heirs"
                    value="LEGACY"
                    type="checkbox"
                  />
                  <InputField
                    name="retirementGoals"
                    label="Capital Preservation"
                    value="CAPITAL"
                    type="checkbox"
                  />
                  <InputField
                    name="retirementGoals"
                    label="Tax Mitigation"
                    value="TAXES"
                    type="checkbox"
                  />
                  <InputField
                    name="retirementGoals"
                    label="Aggressive Asset Growth"
                    value="GROWTH"
                    type="checkbox"
                  />
                </div>
                <div className="mt-4 py-2">
                  <div className="text-base font-semibold text-gray-600">
                    Financial Information
                  </div>
                </div>
                <FieldArray name="assetIncomeSources">
                  {(arrayHelpers: ArrayHelpers) => (
                    <div className="flex flex-grow flex-col">
                      {values.assetIncomeSources.map(
                        (ai: AssetIncomeSource, index) => {
                          if (!ai.hasStartAge && !canSelectAssetOwner) {
                            setFieldValue(
                              `assetIncomeSources.${index}.startAge`,
                              values.currentAge,
                            );
                            setFieldValue(
                              `assetIncomeSources.${index}.assetOwner`,
                              0,
                            );
                          }
                          return (
                            <div
                              className="mb-2 flex flex-row gap-2 p-2 even:bg-blue-gray-50"
                              key={`assetIncomeSources.${ai.kind}.${index}`}
                            >
                              <div className="min-w-40 grow self-center font-light text-gray-700">
                                {ai.name}
                              </div>
                              <div className="flex flex-row flex-wrap gap-2">
                                <InputField
                                  type="number"
                                  name={`assetIncomeSources.${index}.monthlyIncome`}
                                  label="Monthly Income"
                                  fieldClassName="w-28"
                                />
                                {ai.hasValue && (
                                  <InputField
                                    type="number"
                                    name={`assetIncomeSources.${index}.value`}
                                    label="Value"
                                    fieldClassName="w-28"
                                  />
                                )}
                                {ai.hasGrowthRate && (
                                  <InputField
                                    type="number"
                                    name={`assetIncomeSources.${index}.annualGrowthRate`}
                                    label="Growth Rate %"
                                    fieldClassName="w-24"
                                  />
                                )}
                                {ai.hasStartAge && (
                                  <InputField
                                    type="number"
                                    name={`assetIncomeSources.${index}.startAge`}
                                    label="Start Age"
                                    fieldClassName="w-24"
                                  />
                                )}
                                {ai.hasTerm && (
                                  <InputField
                                    type="number"
                                    name={`assetIncomeSources.${index}.term`}
                                    label="Term"
                                    fieldClassName="w-24"
                                  />
                                )}
                                {ai.hasReinvest && (
                                  <InputField
                                    type="number"
                                    name={`assetIncomeSources.${index}.reinvest`}
                                    label="Reinvest Amount"
                                    fieldClassName="w-32"
                                  />
                                )}
                                {ai.hasTaxable && (
                                  <InputField
                                    type="checkbox"
                                    name={`assetIncomeSources.${index}.taxable`}
                                    label="Taxable?"
                                    fieldClassName="w-32"
                                  />
                                )}
                                {canSelectAssetOwner && (
                                  <InputField
                                    type="radio"
                                    selectValues={[
                                      {
                                        id: 0,
                                        name: "Client",
                                      },
                                      { id: 1, name: "Spouse" },
                                    ]}
                                    onChange={(e) => {
                                      if (!ai.hasStartAge) {
                                        setFieldValue(
                                          `assetIncomeSources.${index}.startAge`,
                                          e.target.value === "0"
                                            ? values.currentAge
                                            : values.spouseAge,
                                        );
                                      }
                                      setFieldValue(
                                        `assetIncomeSources.${index}.assetOwner`,
                                        parseInt(e.target.value),
                                      );
                                    }}
                                    disabled={
                                      ai.isCoupleRestricted &&
                                      filter(values.assetIncomeSources, {
                                        kind: ai.kind,
                                      }).length > 1
                                    }
                                    name={`assetIncomeSources.${index}.assetOwner`}
                                    label="Asset Owner"
                                    fieldClassName="w-60"
                                  />
                                )}
                              </div>
                              <button
                                className="btn-outline ml-2 h-10 w-10 self-end"
                                type="button"
                                onClick={() => {
                                  confirmModalRef.current?.setMutate(() => {
                                    arrayHelpers.remove(index);
                                    if (ai.id) {
                                      deleteSource.mutate(ai.id);
                                    }
                                  });
                                  confirmModalRef.current?.setOpen(true);
                                }}
                              >
                                <TrashIcon className="h-5 w-5" />
                              </button>
                            </div>
                          );
                        },
                      )}
                      {values.assetIncomeSources.length <
                        assetIncomeSourceTypes.data.length * 2 && (
                        <div className="mb-2 flex flex-grow flex-row justify-end py-2">
                          <MenuButton
                            menuOptions={reject(
                              assetIncomeSourceTypes.data,
                              (option) =>
                                option.isCoupleRestricted &&
                                filter(values.assetIncomeSources, {
                                  kind: option.kind,
                                }).length > 1,
                            )}
                            buttonText={"Add an Asset/Income"}
                            selectHandler={(option) => {
                              const foundOption = find(
                                values.assetIncomeSources,
                                {
                                  kind: option.kind,
                                },
                              );
                              arrayHelpers.push({
                                ...option,
                                kind: option.kind,
                                assetIncomeSource: option.id,
                                id: null,
                                monthlyIncome: 0,
                                value: 0,
                                startAge: values.retirementAge,
                                annualGrowthRate: 0,
                                term: 100,
                                reinvest: 0,
                                taxable: 1,
                                assetOwner:
                                  canSelectAssetOwner && foundOption
                                    ? Math.abs(foundOption?.assetOwner - 1)
                                    : 0,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </FieldArray>

                <div className="mt-4 py-2">
                  <div className="text-base font-semibold text-gray-600">
                    Tax Rates
                  </div>
                </div>
                <div className="mt-4 py-2">
                  <div className="flex flex-col gap-4 py-2 lg:flex-row">
                    <div className="grow pl-3 pt-3 font-light text-gray-700">
                      Federal Tax Rates
                    </div>
                    <div className="flex flex-row gap-4">
                      <InputField
                        name="federalTaxRateCurrent"
                        label="Current Rate"
                        type="number"
                        fieldClassName="w-40"
                      />
                      <InputField
                        name="federalTaxRateRetirement"
                        label="At Retirement"
                        type="number"
                        fieldClassName="w-40"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 py-2 lg:flex-row">
                    <div className="grow pl-3 pt-3 font-light text-gray-700">
                      State/Local Tax Rates
                    </div>
                    <div className="flex flex-row gap-4">
                      <InputField
                        name="stateLocalTaxRateCurrent"
                        label="Current Rate"
                        type="number"
                        fieldClassName="w-40"
                      />
                      <InputField
                        name="stateLocalTaxRateRetirement"
                        label="At Retirement"
                        type="number"
                        fieldClassName="w-40"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 py-2 lg:flex-row">
                    <div className="grow pl-3 pt-3 font-light text-gray-700">
                      Capital Gains Tax Rates
                    </div>
                    <div className="flex flex-row gap-4">
                      <InputField
                        name="capitalGainsTaxRateCurrent"
                        label="Current Rate"
                        type="number"
                        fieldClassName="w-40"
                      />
                      <InputField
                        name="capitalGainsTaxRateRetirement"
                        label="At Retirement"
                        type="number"
                        fieldClassName="w-40"
                      />
                    </div>
                  </div>
                  <div className="mt-6 py-2">
                    <div className="flex justify-end gap-4">
                      <button
                        type="button"
                        onClick={() => goBack()}
                        className="btn-outline h-10 w-40"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn-primary h-10 w-40"
                        disabled={isSubmitting}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
