import { FileField, InputField } from "components";
import { RefObject } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { BucketReportSegment, PreviewModalRef, IULSegmentData } from "types";
import { isUndefined } from "lodash";
import { useApi } from "api";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SegmentFormFields: React.FC<{
  segment: BucketReportSegment;
  segmentType: string;
  setFieldValue: (field: string, value: any) => void;
  editingSegment: string | undefined;
  previewModalRef: RefObject<PreviewModalRef>;
}> = ({
  segmentType,
  editingSegment,
  previewModalRef,
  segment,
  setFieldValue,
}) => {
  const proposalYear = segment.createdOn
    ? moment(segment.createdOn).year()
    : moment().year();

  const api = useApi();
  const params = useParams<{
    agencyId: string;
    advisorId: string;
    clientId: string;
    reportId: string;
  }>();

  const fileParse = useMutation({
    mutationFn: async (file: File) => {
      if (
        file.type !== "text/csv" &&
        file.type !== "application/vnd.ms-excel" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        api.handleError(
          new AxiosError(
            `Can only parse CSV or Excel files - file of type: ${file.type}`,
          ),
        );
      }
      const data = new FormData();
      data.append("file", file, file.name);
      const response = await api.post<IULSegmentData>(
        `/api/v1/clients/${params.clientId}/bucket-reports/${params.reportId}/parse/`,
        data,
        {
          headers: {
            "Content-Type": `multipart/form-data; `, //boundary=${data._boundary}
          },
        },
      );
      return response.data;
    },
    onSuccess: (data) => {
      setFieldValue("segmentData.parsedFile", data.parsedFile);
      setFieldValue("segmentData.totalPremium", data.totalPremium);
      toast.success("File parsed successfully");
    },
    onError: (error) => {
      console.log("error", error);
      toast.error("Error parsing file");
    },
  });

  return (
    <>
      {segmentType === "vault" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.startingBalance"
            type="number"
            label="Starting Balance"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualGrowthRate"
            type="number"
            label="Growth Rate"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.monthlyIncome"
            type="number"
            label="Monthly Income"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualInflationRate"
            type="number"
            label="Inflation Rate"
            className="mb-2"
          />
        </>
      )}
      {segmentType === "ltc" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.totalPremium"
            type="number"
            label="Total Premium"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.monthlyIncome"
            type="number"
            label="Monthly Benefit"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.startAge"
            type="number"
            label="Start Age"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.term"
            type="number"
            label="Term"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.deathBenefit"
            type="number"
            label="Death Benefit"
            className="mb-2"
          />
        </>
      )}
      {segmentType === "iul" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualGrowthRate"
            type="number"
            label="Growth Rate"
            className="mb-2"
          />
          <div className="mt-2">Income Surrender and Death Benefit</div>
          {segment.segmentCalculation &&
            segment.segmentCalculation.length > 0 && (
              <div className="mt-4 flex h-28 flex-col justify-around text-center align-middle text-sm">
                <button
                  onClick={() => {
                    previewModalRef.current?.setPreviewType("calc");
                    previewModalRef.current?.setSegmentData(segment);
                    previewModalRef.current?.setOpen(true);
                  }}
                  type="button"
                >
                  <div className="btn-link flex flex-col">
                    <FontAwesomeIcon icon={["fas", "calculator"]} size="2x" />
                    View Calculated Values
                  </div>
                </button>
              </div>
            )}
          <FileField
            name="segmentData.parsedFile"
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            uploadFile={(file) => fileParse.mutate(file)}
            loading={fileParse.isPending}
            viewFileValues={() => {
              previewModalRef.current?.setPreviewType("parsed");
              previewModalRef.current?.setSegmentData(segment);
              previewModalRef.current?.setOpen(true);
            }}
            className="mb-2"
          />
          <div className="mt-4 text-center text-sm">csv template</div>
        </>
      )}
      {segmentType === "roth_conv" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.totalPremium"
            type="number"
            label="Premium"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.taxesPaid"
            type="number"
            label="Taxes Paid"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.taxPaidStartYear"
            type="number"
            label="Tax Paid Start"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.taxPaidEndYear"
            type="number"
            label="Tax Paid End"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualGrowthRate"
            type="number"
            label="Growth Rate %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.income"
            type="number"
            label="Income Amount"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.incomeStartYear"
            type="number"
            label="Income Start"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.incomeEndYear"
            type="number"
            label="Income End"
            className="mb-2"
          />
        </>
      )}
      {segmentType === "part_roth" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.totalPremium"
            type="number"
            label="Premium"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.startYear"
            type="number"
            label="Start Year"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.conversionYears"
            type="number"
            label="Conversion Years"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.taxesPaid"
            type="number"
            label="Tax Rate %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualGrowthRate"
            type="number"
            label="Interest Rate %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.feeRate"
            type="number"
            label="Fee Rate %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.bonusRate"
            type="number"
            label="Bonus %"
            className="mb-2"
          />
        </>
      )}
      {segmentType === "annuity" && (
        <>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.totalPremium"
            type="number"
            label="Premium"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.inflationRate"
            type="number"
            label="Inflation %"
            className="mb-2"
          />
          {/* <InputField disabled={
                          isUndefined(editingSegment) ||
                          segment.id !== editingSegment
                        }
            name='segmentData.payoutMethod'
            type='select'
            label='Payout Method'
            selectValues={[
              { id: 'income_rider', name: 'Income Rider' },
              { id: 'annuitization', name: 'Annuitization' },
            ]}
            className='mb-2'
          /> */}
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.startYear"
            type="number"
            label="Start Year"
            className="mb-2"
          />
          <div className="flex flex-row justify-between">
            <div className="w-1/2 flex-col p-2">
              <div className="text-center text-sm text-gray-600">
                Deferral
                <br />
                Years
              </div>
              <div className="text-center text-sm text-gray-600">
                {Math.max(0, segment.segmentData.startYear - 1) ?? "--"}
              </div>
            </div>
            <div className="w-1/2 flex-col p-2">
              <div className="text-center text-sm text-gray-600">
                Year
                <br />
                Start
              </div>
              <div className="text-center text-sm text-gray-600">
                {proposalYear + segment.segmentData.startYear - 1 ?? "--"}
              </div>
            </div>
          </div>
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.monthlyIncome"
            type="number"
            label="Monthly Income"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.inflationAdjustedMonthlyIncome"
            type="number"
            label="Inflation Adj. Inc."
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.bonusType"
            type="select"
            label="Bonus Type"
            selectValues={[
              { id: "income", name: "Income" },
              { id: "premium", name: "Premium" },
            ]}
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.bonusRate"
            type="number"
            label="Bonus %"
            className="mb-2"
          />
          {segment.segmentData.bonusType === "income" && (
            <InputField
              disabled={
                isUndefined(editingSegment) || segment.id !== editingSegment
              }
              name="segmentData.rollupRate"
              type="number"
              label="Rollup %"
              className="mb-2"
            />
          )}
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.annualGrowthRate"
            type="number"
            label="Growth %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.withdrawalRate"
            type="number"
            label="Withdrawal %"
            className="mb-2"
          />
          <InputField
            disabled={
              isUndefined(editingSegment) || segment.id !== editingSegment
            }
            name="segmentData.qualified"
            type="radio"
            label="Qualified"
            selectValues={[
              { id: 1, name: "Yes" },
              { id: 0, name: "No" },
            ]}
            className="mb-2"
          />
        </>
      )}
    </>
  );
};
